







































import Vue from "vue";
import AppAccountLinksNav from "@/components/accounts/navigation/AppAccountLinksNav.vue";
import AppPage from "@/components/layout/page/AppPage.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ProfileView",
  metaInfo: {
    title: "My account",
  },
  components: { AppPage, AppAccountLinksNav },
  data: () => ({}),
  computed: {
    ...mapGetters("UserStore", ["user"]),
    welcome(): string {
      return `Hello, ${this.user.firstName ?? ""}`;
    },
  },
});
